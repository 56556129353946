$(document).on('turbolinks:load', function () {
  if (window.location.href.indexOf("/quizzes") > -1 && window.location.href.indexOf("/results") == -1 && window.location.href.indexOf("/questions") == -1 && window.location.href.indexOf("/admin") == -1) {

    if (!checkCurrentUser()) {
      $('.homepage_section_quizzes_item').each(function () {
        quizId = $(this).attr("data-quiz");

        quizKey = 'user_quiz_' + quizId + '_result';

        if (quizKey in localStorage) {
          quizLocalData = JSON.parse(localStorage.getItem(quizKey));

          if (quizLocalData.finished == "finished") {
            $('.results_quiz_btn', this).addClass('active');

            url = '/quizzes/' + quizId + '/results/';

            $('.results_quiz_btn', this).attr('href', url);

            $('.continue_quiz_btn', this).parent().addClass('hidden');
          } else {
            $('.continue_quiz_btn', this).addClass('active');

            url = '/quizzes/' + quizId + '/questions/' + (parseInt(quizLocalData.last_question_number) + 1);

            $('.continue_quiz_btn', this).attr('href', url);

            $('.results_quiz_btn', this).parent().addClass('hidden');
          }
        }
      });
    }
  } else if (window.location.href.indexOf("/questions") > -1 && window.location.href.indexOf("/admin") == -1) {

    if (!checkCurrentUser()) {
      quizId = $('.quiz_answer_submit_btn').attr("data-quiz");
      quizKey = 'user_quiz_' + quizId + '_result';

      questionNumber = $('.quiz_answer_submit_btn').attr("data-question");

      if (quizKey in localStorage) {
        urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('start_new')) {
          localStorage.removeItem(quizKey);

          quizResultItem = {
            last_question_number: "0",
            result: "0",
            finished: "false",
            finished_at: "",
            quiz_id: quizId
          }

          localStorage.setItem(quizKey, JSON.stringify(quizResultItem));

          if (questionNumber != "1") {
            url = '/quizzes/' + quizId + '/questions/1';

            $(location).attr('href', url);
          }
        } else {
          quizLocalData = JSON.parse(localStorage.getItem(quizKey));

          if (quizLocalData.finished == "finished") {
            url = '/quizzes/' + quizId + '/results/';

            $(location).attr('href', url);
          } else if (parseInt(questionNumber) != parseInt(quizLocalData.last_question_number) + 1) {

            url = '/quizzes/' + quizId + '/questions/' + (parseInt(quizLocalData.last_question_number) + 1);

            $(location).attr('href', url);
          }
        }
      } else {
        quizResultItem = {
          last_question_number: "0",
          result: "0",
          finished: "false",
          finished_at: "",
          quiz_id: quizId
        }

        localStorage.setItem(quizKey, JSON.stringify(quizResultItem));

        url = '/quizzes/' + quizId + '/questions/' + (parseInt(quizResultItem.last_question_number) + 1);

        $(location).attr('href', url);
      }
    }
  } else if (window.location.href.indexOf("/quizzes") > -1 && window.location.href.indexOf("/results") > -1 && window.location.href.indexOf("/admin") == -1) {

    if (!checkCurrentUser()) {
      quizId = $('#user_quiz_result_container').attr("data-quiz");
      quizKey = 'user_quiz_' + quizId + '_result';

      if (quizKey in localStorage) {

        quizLocalData = JSON.parse(localStorage.getItem(quizKey));

        console.log(quizLocalData);

        if (quizLocalData.finished == "finished") {
          questionsCount    = $('#user_quiz_result_percent').attr("data-quiz-questions");
          correctAnswers    = parseInt(quizLocalData.result);
          // correctPercentage = ((correctAnswers / questionsCount) * 100).toFixed(2) + '%';

          $('#user_quiz_result_correct_qty').text(correctAnswers);
          // $('#user_quiz_result_percent').text(correctPercentage);

        } else {
          url = '/quizzes/' + quizId + '/questions/' + (parseInt(quizLocalData.last_question_number) + 1);

          // $(location).attr('href', url);
        }
      } else {
        quizResultItem = {
          last_question_number: "0",
          result: "0",
          finished: "false",
          finished_at: "",
          quiz_id: quizId
        }

        localStorage.setItem(quizKey, JSON.stringify(quizResultItem));

        console.log('results no quizkey');

        url = '/quizzes/' + quizId + '/questions/' + (parseInt(quizResultItem.last_question_number) + 1);

        // $(location).attr('href', url);
      }
    }
  }

  $('.answer_item_checkbox').on('change', function(){
    $('#answer_submit_btn').removeClass('disabled');
  });

  $('#answer_submit_btn').on('click', function() {
    checkedItem = $('.answer_item_checkbox:checked')
    dataCorrect = checkedItem.attr("data-correct");

    quizId = $(this).attr("data-quiz");
    dataQuestionNumber = $(this).attr("data-question");
    dataLastOne = $(this).attr("data-finish");

    quizKey = 'user_quiz_' + quizId + '_result';

    var correctAnswer;

    if (dataCorrect === "true") {
      checkedItem.parent().addClass('correct');

      correctAnswer = 1;
    } else {
      checkedItem.parent().addClass('wrong');
      $('.answer_item_checkbox[data-correct="true"]').parent().addClass('correct');

      correctAnswer = 0;
    }

    if (checkCurrentUser()) {
      $.ajax({
        type: "GET",
        url: '/quizzes/' + quizId + '/update_user_result',
        async: false,
        data: ({ quiz_id: quizId, question_number: dataQuestionNumber , answer_result: correctAnswer }),
        dataType: "html",
        success: function(result) {
        },
        error: function() {
        }
      });
    } else {
      if (quizKey in localStorage) {
        quizLocalData = JSON.parse(localStorage.getItem(quizKey));

        quizLocalData.result = parseInt(quizLocalData.result) + correctAnswer;

        quizLocalData.last_question_number = parseInt(dataQuestionNumber);

        if (dataLastOne === "true") {
          quizLocalData.finished = "finished";
          quizLocalData.finished_at = new Date($.now());
        }

        console.log("Submit question quizkey");
        console.log(quizLocalData);

        localStorage.setItem(quizKey, JSON.stringify(quizLocalData));
      } else {
        quizResultItem = {
          last_question_number: dataQuestionNumber,
          result: correctAnswer,
          finished: "false",
          finished_at: "",
          quiz_id: quizId
        }

        console.log("Submit question no quizkey");
        console.log(quizResultItem);

        localStorage.setItem(quizKey, JSON.stringify(quizResultItem));
      }
    }

    $('.answer_item_checkbox').each(function () {
      if ($(this).is(":not(:checked)")) {
         $(this).attr('disabled', true);
       }
    });

    $('#answer_submit_btn').addClass('hidden');
    $('#next_question_btn').removeClass('hidden');
  });

  function checkCurrentUser() {
    var isLoggedIn;

    $.ajax({
      type: "GET",
      url: '/check_current_user',
      async: false,
      dataType: "json",
      success: function(result) {
        isLoggedIn = result.logged_in;
      },
      error: function() {
      }
    });

    return isLoggedIn;
  }
});
