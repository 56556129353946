$(document).on('turbolinks:load', function () {
  $('.education_quest_answer_form_submit').on('click', function(e) {
    e.preventDefault();

    questIdInput  = $(this).parent().parent().find('#quest_answer_quest_id');
    codeInput = $(this).parent().parent().find('#quest_answer_code');
    descriptionInput  = $(this).parent().parent().find('#quest_answer_description');

    correctFieldsCounter = 0;

    $(this).parent().parent().find('.education_quest_answer_form_input_error').removeClass('active');
    $(this).parent().parent().find('.education_quest_answer_form_input').removeClass('error, correct');

    if ( codeInput.length > 0 ) {
      if ( codeInput.val().length === 0 ) {
        codeInput.addClass('error');
        codeInput.parent().parent().find('.education_quest_answer_form_input_error.empty').addClass('active');
      } else {
        if ( checkCorrectCode(questIdInput.val(), codeInput.val()) ) {
          codeInput.addClass('correct');
          correctFieldsCounter++;
        } else {
          codeInput.addClass('error');
          codeInput.parent().parent().find('.education_quest_answer_form_input_error.incorrect').addClass('active');
        }
      }
    } else {
      correctFieldsCounter++
    }

    if ( descriptionInput.length > 0 ) {
      if ( descriptionInput.val().length === 0 ) {
        descriptionInput.addClass('error');
        descriptionInput.parent().parent().find('.education_quest_answer_form_input_error.empty').addClass('active');
      } else {
        descriptionInput.addClass('correct');
        correctFieldsCounter++;
      }
    } else {
      correctFieldsCounter++
    }

    if ( correctFieldsCounter === 2) {
      $(this).parent().parent().parent().removeClass('show');
      $(this).parent().parent().parent().next().addClass('show');

      if ( descriptionInput.length > 0 ) {
        $(this).parent().parent().parent().next().find('.admin_quest_answers_item_text').html('<i>' + descriptionInput.val() + '</i>');
      } else {
        $(this).parent().parent().parent().next().find('.education_quest_answer_form_answer_text_on_submit').hide();
      }

      $(this).parent().parent().submit();
    }
  });

  $('#user_data_form_submit').on('click', function(e) {
    e.preventDefault();

    userDataWeight = $(this).parent().find('#food_diary_user_data_weight');
    userDataHeight = $(this).parent().find('#food_diary_user_data_height');
    userDataAge    = $(this).parent().find('#food_diary_user_data_age');

    correctFieldsCounter = 0;

    $('.food_diary_user_data_form_input_error').removeClass('active');
    $('.food_diary_user_data_form_input').removeClass('error, correct');

    if ( userDataWeight.val().length === 0  ) {
      userDataWeight.addClass('error');
      $('#food_diary_user_data_weight_error').addClass('active');
    } else {
      userDataWeight.addClass('correct');
      correctFieldsCounter++;
    }

    if ( userDataHeight.val().length === 0  ) {
      userDataHeight.addClass('error');
      $('#food_diary_user_data_height_error').addClass('active');
    } else {
      userDataHeight.addClass('correct');
      correctFieldsCounter++;
    }

    if ( userDataAge.val().length === 0  ) {
      userDataAge.addClass('error');
      $('#food_diary_user_data_age_error').addClass('active');
    } else {
      userDataAge.addClass('correct');
      correctFieldsCounter++;
    }

    if ( correctFieldsCounter === 3) {
      $('#user_data_form').submit();
    }
  });

  $('#user_product_form_submit').on('click', function(e) {
    e.preventDefault();

    userProductName          = $(this).parent().find('#user_product_name');
    userProductCategory      = $(this).parent().find('#user_product_category');
    userProductCalories      = $(this).parent().find('#user_product_calories');
    userProductFats          = $(this).parent().find('#user_product_fats');
    userProductCarbohydrates = $(this).parent().find('#user_product_carbohydrates');
    userProductProteins      = $(this).parent().find('#user_product_proteins');

    correctFieldsCounter = 0;

    $('.food_diary_user_data_form_input_error').removeClass('active');
    $('.food_diary_user_data_form_input').removeClass('error, correct');

    if ( userProductName.val().length === 0  ) {
      userProductName.addClass('error');
      $('#user_product_name_error').addClass('active');
    } else {
      userProductName.addClass('correct');
      correctFieldsCounter++;
    }

    if ( userProductCategory.val().length === 0  ) {
      userProductCategory.addClass('error');
      $('#user_product_category_error').addClass('active');
    } else {
      userProductCategory.addClass('correct');
      correctFieldsCounter++;
    }

    if ( userProductCalories.val().length === 0  ) {
      userProductCalories.addClass('error');
      $('#user_product_calories_error').addClass('active');
    } else {
      if (!$.isNumeric(userProductCalories.val())) {
        userProductCalories.addClass('error');
        $('#user_product_calories_numeric_error').addClass('active');
      } else {
        userProductCalories.addClass('correct');
        correctFieldsCounter++;
      }
    }

    if ( userProductFats.val().length === 0  ) {
      userProductFats.addClass('error');
      $('#user_product_fats_error').addClass('active');
    } else {
      if (!$.isNumeric(userProductFats.val())) {
        userProductFats.addClass('error');
        $('#user_product_fats_numeric_error').addClass('active');
      } else {
        userProductFats.addClass('correct');
        correctFieldsCounter++;
      }
    }

    if ( userProductCarbohydrates.val().length === 0  ) {
      userProductCarbohydrates.addClass('error');
      $('#user_product_carbohydrates_error').addClass('active');
    } else {
      if (!$.isNumeric(userProductCarbohydrates.val())) {
        userProductCarbohydrates.addClass('error');
        $('#user_product_carbohydrates_numeric_error').addClass('active');
      } else {
        userProductCarbohydrates.addClass('correct');
        correctFieldsCounter++;
      }
    }

    if ( userProductProteins.val().length === 0  ) {
      userProductProteins.addClass('error');
      $('#user_product_proteins_error').addClass('active');
    } else {
      if (!$.isNumeric(userProductProteins.val())) {
        userProductProteins.addClass('error');
        $('#user_product_proteins_numeric_error').addClass('active');
      } else {
        userProductProteins.addClass('correct');
        correctFieldsCounter++;
      }
    }

    if ( correctFieldsCounter === 6) {
      $('#user_product_form').submit();
    }
  });

  $('.education_navbar_module_item').on('click', function() {
    lessonsContainer = $(this).parent().find('.education_navbar_module_lessons_container')

    if (lessonsContainer.hasClass('open')) {
      lessonsContainer.removeClass('open');
    } else {
      lessonsContainer.addClass('open');
    }
  });

  $('.checkout_user_login_trigger').on('click', function() {
    $('.course_checkout_user_form_container').removeClass('active');

    $('#checkout_user_login').addClass('active');
  });

  $('.checkout_user_reg_trigger').on('click', function() {
    $('.course_checkout_user_form_container').removeClass('active');

    $('#checkout_user_reg').addClass('active');
  });

  $('.checkout_user_password_trigger').on('click', function() {
    $('.course_checkout_user_form_container').removeClass('active');

    $('#checkout_user_password').addClass('active');
  });

  $('.user_form_show_password').click(function() {
    prevInput = $(this).parent().find('.course_checkout_user_form_input.password');

    if ( prevInput.hasClass('show') ) {
      $(this).removeClass('show');
      prevInput.removeClass('show');
      prevInput.attr('type', 'password');
    } else {
      $(this).addClass('show');
      prevInput.addClass('show');
      prevInput.attr('type', 'text');
    }
  });

  $('#reg_form_submit').on('click', function(e) {
    e.preventDefault();

    firstNameInput = $(this).parent().find('#checkout_user_reg_first_name');
    lastNameInput  = $(this).parent().find('#checkout_user_reg_last_name');
    emailInput     = $(this).parent().find('#checkout_user_reg_email');
    passwordInput  = $(this).parent().find('#checkout_user_reg_password');

    correctFieldsCounter = 0;

    $('.course_checkout_user_form_input_error').removeClass('active');
    $('.course_checkout_user_form_input').removeClass('error, correct');

    if ( firstNameInput.val().length === 0 ) {
      firstNameInput.addClass('error');
      $('#checkout_user_reg_first_name_error').addClass('active');
    } else {
      firstNameInput.addClass('correct');
      correctFieldsCounter++;
    }

    if ( lastNameInput.val().length === 0 ) {
      lastNameInput.addClass('error');
      $('#checkout_user_reg_last_name_error').addClass('active');
    } else {
      lastNameInput.addClass('correct');
      correctFieldsCounter++;
    }

    emailRegex = /@/i;

    if ( emailInput.val().length === 0  ) {
      emailInput.addClass('error');
      $('#checkout_user_reg_email_error').addClass('active');
    } else {
      if ( emailRegex.test(emailInput.val()) ) {
        if ( !checkUniqueEmail(emailInput.val()) ) {
          emailInput.addClass('correct');
          correctFieldsCounter++;
        } else {
          emailInput.addClass('error');
          $('#checkout_user_reg_email_error_unique').addClass('active');
        }
      } else {
        emailInput.addClass('error');
        $('#checkout_user_reg_email_error_format').addClass('active');
      }
    }

    if ( passwordInput.val().length === 0 ) {
      passwordInput.addClass('error');
      $('#checkout_user_reg_password_error').addClass('active');
    } else {
      passwordInput.addClass('correct');
      correctFieldsCounter++;
    }

    if ( correctFieldsCounter === 4) {
      $('#reg_form').submit();
    }
  });

  $('#login_form_submit').on('click', function(e) {
    e.preventDefault();

    if ($(this).hasClass('admin_signin')) {
      $('#login_form').submit();
    } else {
      emailInput     = $(this).parent().find('#checkout_user_login_email');
      passwordInput  = $(this).parent().find('#checkout_user_login_password');

      correctFieldsCounter = 0;

      $('.course_checkout_user_form_input_error').removeClass('active');
      $('.course_checkout_user_form_input').removeClass('error, correct');

      emailRegex = /@/i;

      if ( emailInput.val().length === 0  ) {
        emailInput.addClass('error');
        $('#checkout_user_login_email_error').addClass('active');
      } else {
        if ( emailRegex.test(emailInput.val()) ) {
          if ( checkUniqueEmail(emailInput.val()) ) {
            emailInput.addClass('correct');
            correctFieldsCounter++;
          } else {
            emailInput.addClass('error');
            $('#checkout_user_login_email_error_unique').addClass('active');
          }
        } else {
          emailInput.addClass('error');
          $('#checkout_user_login_email_error_format').addClass('active');
        }
      }

      if ( passwordInput.val().length === 0 ) {
        passwordInput.addClass('error');
        $('#checkout_user_login_password_error').addClass('active');
      } else {
        if ( correctFieldsCounter > 0 ) {
          if ( checkCorrectCredentials(emailInput.val(), passwordInput.val()) ) {
            passwordInput.addClass('correct');
            correctFieldsCounter++;
          } else {
            passwordInput.addClass('error');
            $('#checkout_user_login_password_error_correct').addClass('active');
          }
        }
      }

      if ( correctFieldsCounter === 2) {
        $('#login_form').submit();
      }
    }
  });

  $('#password_form_submit').on('click', function(e) {
    e.preventDefault();

    emailInput = $(this).parent().find('#checkout_user_forgot_email');

    correctFieldsCounter = 0;

    $('.course_checkout_user_form_input_error').removeClass('active');
    $('.course_checkout_user_form_input').removeClass('error, correct');

    emailRegex = /@/i;

    if ( emailInput.val().length === 0  ) {
      emailInput.addClass('error');
      $('#checkout_user_forgot_email_error').addClass('active');
    } else {
      if ( emailRegex.test(emailInput.val()) ) {
        if ( checkUniqueEmail(emailInput.val()) ) {
          emailInput.addClass('correct');
          correctFieldsCounter++;
        } else {
          emailInput.addClass('error');
          $('#checkout_user_forgot_email_error_unique').addClass('active');
        }
      } else {
        emailInput.addClass('error');
        $('#checkout_user_forgot_email_error_format').addClass('active');
      }
    }

    if ( correctFieldsCounter === 1) {
      var form = $('#password_form');
      var url = form.attr('action');

      $.ajax({
        type: "POST",
        url: url,
        data: form.serialize(),
        async: false,
        dataType: "json",
        success: function(result) {
          $('.course_checkout_password_form_container').addClass('hidden');
          $('.course_checkout_password_mail_sent_container').addClass('active');
        }
      });
    }
  });

  $('#new_password_form_submit').on('click', function(e) {
    e.preventDefault();

    passwordInput             = $(this).parent().find('#checkout_user_new_password');
    passwordConfirmationInput = $(this).parent().find('#checkout_user_new_password_confirmation');

    correctFieldsCounter = 0;

    $('.course_checkout_user_form_input_error').removeClass('active');
    $('.course_checkout_user_form_input').removeClass('error, correct');

    if ( passwordInput.val().length === 0 ) {
      passwordInput.addClass('error');
      $('#checkout_user_new_password_error').addClass('active');
    } else {
      passwordInput.addClass('correct');
      correctFieldsCounter++;
    }

    if ( passwordConfirmationInput.val().length === 0 ) {
      passwordConfirmationInput.addClass('error');
      $('#checkout_user_new_password_confirmation_error').addClass('active');
    } else {
      if ( passwordInput.val() === passwordConfirmationInput.val() ) {
        passwordConfirmationInput.addClass('correct');
        correctFieldsCounter++;
      } else {
        passwordConfirmationInput.addClass('error');
        $('#checkout_user_new_password_confirmation_error_identical').addClass('active');
      }
    }

    if ( correctFieldsCounter === 2) {
      $('#new_password_form').submit();
    }
  });

  $('#courses_list_item_recording_form_submit').on('click', function(e) {
    e.preventDefault();

    emailInput = $(this).parent().find('#courses_list_item_recording_form_email');

    correctFieldsCounter = 0;

    $('.courses_list_item_recording_form_input_error').removeClass('active');
    $('.courses_list_item_recording_form_email').removeClass('error, correct');

    emailRegex = /@/i;

    if ( emailInput.val().length === 0  ) {
      emailInput.addClass('error');
      $('#courses_list_item_recording_email_error').addClass('active');
    } else {
      if ( emailRegex.test(emailInput.val()) ) {
        if ( checkUniqueEmailWaitingUser(emailInput.val()) ) {
          emailInput.addClass('error');
          $('#courses_list_item_recording_email_error_unique').addClass('active');
        } else {
          emailInput.addClass('correct');
          correctFieldsCounter++;
        }
      } else {
        emailInput.addClass('error');
        $('#courses_list_item_recording_error_format').addClass('active');
      }
    }

    if ( correctFieldsCounter === 1) {
      $('#courses_list_item_recording_form').submit();
    }
  });

  $('#food_diary_water_form_submit').on('click', function(e) {
    e.preventDefault();

    waterInput = $(this).parent().find('#food_diary_water_ml');

    correctFieldsCounter = 0;

    $('.food_results_item_actions_input_error').removeClass('active');
    $('.food_results_item_actions_input').removeClass('error, correct');

    if ( waterInput.val().length === 0 ) {
      waterInput.addClass('error');
      $('#food_diary_water_ml_empty').addClass('active');
    } else {
      if (!$.isNumeric(itemGrams)) {
        waterInput.addClass('error');
        $('#food_diary_water_ml_numeric').addClass('active');
      } else {
        waterInput.addClass('correct');
        correctFieldsCounter++;
      }
    }

    if ( correctFieldsCounter === 1) {
      $('#food_diary_water_form').submit();
    }
  });

  function checkCorrectCode(quest, code) {
    var isCorrect;

    $.ajax({
      type: "GET",
      url: '/check_correct_code?quest_id=' + quest + '&code=' + code,
      async: false,
      dataType: "json",
      success: function(result) {
        isCorrect = result.code_correct;
      },
      error: function() {
      }
    });

    return isCorrect;
  }

  function checkUniqueEmail(email) {
    var isUnique;

    $.ajax({
      type: "GET",
      url: '/check_unique_email?email=' + email,
      async: false,
      dataType: "json",
      success: function(result) {
        isUnique = result.user_present;
      },
      error: function() {
      }
    });

    return isUnique;
  }

  function checkUniqueEmailWaitingUser(email) {
    var isUnique;

    $.ajax({
      type: "GET",
      url: '/check_unique_email_waiting_user?email=' + email,
      async: false,
      dataType: "json",
      success: function(result) {
        isPresent = result.user_present;
      },
      error: function() {
      }
    });

    return isPresent;
  }

  function checkCorrectCredentials(email, password) {
    var isCorrect;

    $.ajax({
      type: "GET",
      url: '/check_correct_credentials?email=' + email + '&password=' + password,
      async: false,
      dataType: "json",
      success: function(result) {
        isCorrect = result.correct_credentials;
      },
      error: function() {
      }
    });

    return isCorrect;
  }
});
