require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import '../stylesheets/application'

import 'bootstrap/dist/js/bootstrap'
import '../packs/script'
import '../packs/validations'
import '../packs/quizzes'

import $ from 'jquery';
global.$ = jQuery;
